import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import PaymentDepositProviderTruelayerScripts from '../../../provider/truelayer/scripts/PaymentDepositProviderTruelayerScripts';
import { logger } from '../../../../../../services/logger';
import { isProduction } from '../../../../../../services/util';
import { isStage } from '../../../../../../services/environment';

interface Props {
    response: Record<string, string>;
    setIsError: (value: boolean) => void;
    onClose: () => void;
}

export default function PaymentDepositPayAndPlayProvidersTruelayer({ response, setIsError, onClose }: Props) {
    const [hasScriptsLoaded, setHasScriptsLoaded] = useState(false);

    useEffect(() => {
        if (hasScriptsLoaded) {
            tryDeposit();
        }
    }, [hasScriptsLoaded]);

    async function tryDeposit() {
        try {
            let payload: any = { ...response, production: isProduction() };
            if (isStage()) {
                payload = { ...payload, onAbort: onClose() };
            } else {
                const target = document.getElementById('truelayer-pnp-container-id');
                payload = {
                    ...payload,
                    target: target!,
                    onDone: () => target?.remove(),
                    onAbort: () => {
                        target?.remove();
                        onClose();
                    },
                };
            }
            const payment = await Truelayer.Payment(payload);
            payment.start();
        } catch (error) {
            logger.error('PaymentDepositPayAndPlayProvidersTruelayer', 'tryDeposit', error);
            setIsError(true);
        }
    }

    return (
        <Wrapper id="truelayer-pnp-container-id">
            <PaymentDepositProviderTruelayerScripts
                onError={() => setIsError(true)}
                onScriptsLoaded={() => setHasScriptsLoaded(true)}
            />
        </Wrapper>
    );
}

declare let Truelayer: {
    Payment: (...args: unknown[]) => Promise<{
        start: () => void;
    }>;
};
